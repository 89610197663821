import ConfigItem from '../components/ConfigItem';
import CodeMirror from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import { EditorView } from '@codemirror/view';
import { dracula } from '@uiw/codemirror-theme-dracula';
import { useState, useEffect } from 'react';
import { useMedia } from 'react-use';
import ReactGA from 'react-ga4';
import { Copy } from 'react-feather';
import Button from '../components/Button';

const defaultConfig = {
  silent: false,
  objectSimplification: {
    isEnabled: true,
    unsafeReplace: true,
  },
  objectPacking: {
    isEnabled: true,
  },
  proxyFunctionInlining: {
    isEnabled: true,
  },
  stringRevealing: {
    isEnabled: true,
  },
  expressionSimplification: {
    isEnabled: true,
  },
  constantPropagation: {
    isEnabled: true,
  },
  reassignmentRemoval: {
    isEnabled: true,
  },
  sequenceSplitting: {
    isEnabled: true,
  },
  controlFlowRecovery: {
    isEnabled: true,
  },
  deadBranchRemoval: {
    isEnabled: true,
  },
  antiTamperRemoval: {
    isEnabled: true,
  },
  unusedVariableRemoval: {
    isEnabled: true,
  },
  propertySimplification: {
    isEnabled: true,
  },
};

/**
 * The deobfuscation page.
 * @returns The deobfuscation page component.
 */
export default function DeobfuscationPage() {
  const isLg = useMedia('(min-width:1024px)');
  const [code, setCode] = useState('');
  const [deobfuscatedCode, setDeobfuscatedCode] = useState('');
  const [addedScripts, setAddedScripts] = useState(false);
  const [config, setConfig] = useState(defaultConfig);

  /**
   * Loads in the scripts required for deobfuscation.
   */
  useEffect(() => {
    if (!addedScripts) {
      const bufferScript = document.createElement('script');
      bufferScript.src = 'https://bundle.run/buffer@6.0.3';
      bufferScript.onload = () => {
        const setBufferScript = document.createElement('script');
        setBufferScript.innerText = 'window.Buffer = buffer.Buffer';
        document.body.appendChild(setBufferScript);

        const obfuscatorScript = document.createElement('script');
        obfuscatorScript.src = `${process.env.PUBLIC_URL}/deobfuscator.js`;
        document.body.appendChild(obfuscatorScript);
      };
      document.body.appendChild(bufferScript);

      setAddedScripts(true);
    }
  }, [addedScripts]);

  /**
   * Initialise Google Analytics.
   */
  useEffect(() => {
    ReactGA.initialize('G-PK6YQBK0W0');
    ReactGA.send({ hitType: 'pageview' });
  }, []);

  if (isLg) {
    return (
      <div className="w-screen h-screen bg-[#202129] flex flex-col overflow-y-scroll">
        <a
          className="w-full py-2 mb-2 bg-[#1e90ff] text-center rounded-b-md"
          href="https://blog.deobfuscate.io/reversing-vmcrack"
          target="_blank"
          rel="noreferrer"
        >
          🎉 New blog post: Reversing VMCrack 🎉
        </a>
        <div className="flex justify-between mt-3 px-12">
          <div className="flex flex-col">
            <span className="text-3xl font-bold">Obfuscator.io Deobfuscator</span>
            <span className="text-lg text-gray-300 font-semibold">
              A tool to undo obfuscation performed by{' '}
              <a href="https://obfuscator.io" className="text-blue-400">
                obfuscator.io
              </a>
            </span>
          </div>

          <div className="flex gap-6">
            <Button
              variant="primary"
              className="px-6 h-10"
              onClick={() => window.open('https://blog.deobfuscate.io', '_blank').focus()}
            >
              Blog
            </Button>
            <Button
              variant="secondary"
              className="px-6 h-10"
              onClick={() => window.open('https://discord.gg/KQjZx2X28n', '_blank').focus()}
            >
              Discord
            </Button>

            <a
              className="w-28 h-10 flex items-center"
              href="https://github.com/ben-sb/obfuscator-io-deobfuscator"
            >
              <img src="/github-logo.png" alt="GitHub Logo" draggable="false" />
            </a>
          </div>
        </div>

        <div className="self-center max-w-7xl w-full h-full min-h-[500px] px-10 mt-5 flex flex-col">
          <div className="w-full min-h-[400px] h-full flex flex-row">
            <div className="w-full h-full p-2">
              <CodeMirror
                theme={dracula}
                height="100%"
                style={{ height: '100%', fontSize: '9pt' }}
                value={code}
                onChange={(code) => setCode(code)}
                extensions={[javascript(), EditorView.lineWrapping]}
              />
            </div>

            <div className="w-64 xl:w-80 flex flex-col justify-end">
              <Button
                variant="primary"
                className="h-10 mb-2"
                onClick={() => {
                  if (window.deobfuscator) {
                    const output = window.deobfuscator.deobfuscate(code, config);
                    setDeobfuscatedCode(output);
                  }
                }}
              >
                Deobfuscate
              </Button>
            </div>

            <div className="relative w-full h-full p-2">
              <CodeMirror
                theme={dracula}
                height="100%"
                style={{ height: '100%', fontSize: '9pt' }}
                value={deobfuscatedCode}
                readOnly={true}
                extensions={[javascript(), EditorView.lineWrapping]}
              />
              <Copy
                className="absolute top-6 right-6 stroke-gray-400 cursor-pointer"
                onClick={() => navigator.clipboard.writeText(deobfuscatedCode)}
              />
            </div>
          </div>

          <div className="w-full h-40 my-5 shrink-0 flex flex-col px-10 bg-[#282a35] rounded-xl">
            <div className="w-full h-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 items-center">
              <ConfigItem
                name="Simplify Expressions"
                initialValue={config.expressionSimplification.isEnabled}
                onChange={(value) =>
                  setConfig(((config.expressionSimplification.isEnabled = value), config))
                }
              />
              <ConfigItem
                name="Simplify Properties"
                initialValue={config.propertySimplification.isEnabled}
                onChange={(value) =>
                  setConfig(((config.propertySimplification.isEnabled = value), config))
                }
              />
              <ConfigItem
                name="Simplify Objects"
                initialValue={config.objectSimplification.isEnabled}
                onChange={(value) =>
                  setConfig(
                    ((config.objectSimplification.isEnabled = config.objectPacking.isEnabled =
                      value),
                    config),
                  )
                }
              />
              <ConfigItem
                name="Remove Proxy Functions"
                initialValue={config.proxyFunctionInlining.isEnabled}
                onChange={(value) =>
                  setConfig(((config.proxyFunctionInlining.isEnabled = value), config))
                }
              />
              <ConfigItem
                name="Recover Strings"
                initialValue={config.stringRevealing.isEnabled}
                onChange={(value) =>
                  setConfig(((config.stringRevealing.isEnabled = value), config))
                }
              />
              <ConfigItem
                name="Recover Control Flow"
                initialValue={config.controlFlowRecovery.isEnabled}
                onChange={(value) =>
                  setConfig(
                    (((config.controlFlowRecovery.isEnabled = value),
                    (config.sequenceSplitting.isEnabled = value)),
                    config),
                  )
                }
              />
              <ConfigItem
                name="Remove Anti-Tamper"
                initialValue={config.antiTamperRemoval.isEnabled}
                onChange={(value) =>
                  setConfig(((config.antiTamperRemoval.isEnabled = value), config))
                }
              />
              <ConfigItem
                name="Remove Dead Code"
                initialValue={config.deadBranchRemoval.isEnabled}
                onChange={(value) =>
                  setConfig(
                    ((config.deadBranchRemoval.isEnabled =
                      config.constantPropagation.isEnabled =
                      config.reassignmentRemoval.isEnabled =
                      config.unusedVariableRemoval.isEnabled =
                        value),
                    config),
                  )
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="w-screen h-full bg-[#202129] flex flex-col">
        <a
          className="w-full py-2 mb-2 bg-[#1e90ff] text-center rounded-b-md"
          href="https://blog.deobfuscate.io/reversing-vmcrack"
          target="_blank"
          rel="noreferrer"
        >
          🎉 New blog post: Reversing VMCrack 🎉
        </a>
        <div className="flex flex-col my-3 ml-2 px-10">
          <div className="flex flex-col">
            <span className="text-3xl font-bold">Obfuscator.io Deobfuscator</span>
            <span className="text-lg text-gray-300 font-semibold">
              A tool to undo obfuscation performed by{' '}
              <a href="https://obfuscator.io" className="text-blue-400">
                obfuscator.io
              </a>
            </span>
          </div>

          <div className="flex items-center py-2 mt-3 gap-4">
            <Button
              variant="primary"
              className="px-6 h-8"
              onClick={() => window.open('https://blog.deobfuscate.io', '_blank').focus()}
            >
              Blog
            </Button>
            <Button
              variant="secondary"
              className="px-6 h-8"
              onClick={() => window.open('https://discord.gg/KQjZx2X28n', '_blank').focus()}
            >
              Discord
            </Button>

            <a
              className="w-28 h-8 flex items-center"
              href="https://github.com/ben-sb/obfuscator-io-deobfuscator"
            >
              <img src="/github-logo.png" alt="GitHub Logo" draggable="false" />
            </a>
          </div>
        </div>
        <div className="w-full h-auto flex flex-col items-center shrink-0 px-10">
          <div className="w-full h-[300px] min-h-[300px] p-2">
            <CodeMirror
              theme={dracula}
              height="100%"
              style={{ height: '100%', fontSize: '9pt' }}
              value={code}
              onChange={(code) => setCode(code)}
              extensions={[javascript(), EditorView.lineWrapping]}
            />
          </div>

          <div className="w-64 mt-2 flex flex-col justify-end">
            <Button
              variant="primary"
              className="w-full h-10 mb-2"
              onClick={() => {
                if (window.deobfuscator) {
                  const output = window.deobfuscator.deobfuscate(code, config);
                  setDeobfuscatedCode(output);
                }
              }}
            >
              Deobfuscate
            </Button>
          </div>

          <div className="relative w-full h-[300px] min-h-[300px] p-2">
            <CodeMirror
              theme={dracula}
              height="100%"
              style={{ height: '100%', fontSize: '9pt' }}
              value={deobfuscatedCode}
              readOnly={true}
              extensions={[javascript(), EditorView.lineWrapping]}
            />
            <Copy
              className="absolute top-6 right-6 stroke-gray-400 cursor-pointer"
              onClick={() => navigator.clipboard.writeText(deobfuscatedCode)}
            />
          </div>
        </div>

        <div className="w-full h-auto px-10">
          <div className="w-full h-auto mt-5 mb-10 py-5 flex flex-col shrink-0 px-10 bg-[#282a35] rounded-md">
            <div className="w-full h-auto grid grid-cols-2 gap-4 items-center">
              <ConfigItem
                name="Simplify Expressions"
                initialValue={config.expressionSimplification.isEnabled}
                onChange={(value) =>
                  setConfig(((config.expressionSimplification.isEnabled = value), config))
                }
              />
              <ConfigItem
                name="Simplify Properties"
                initialValue={config.propertySimplification.isEnabled}
                onChange={(value) =>
                  setConfig(((config.propertySimplification.isEnabled = value), config))
                }
              />
              <ConfigItem
                name="Simplify Objects"
                initialValue={config.objectSimplification.isEnabled}
                onChange={(value) =>
                  setConfig(
                    ((config.objectSimplification.isEnabled = config.objectPacking.isEnabled =
                      value),
                    config),
                  )
                }
              />
              <ConfigItem
                name="Remove Proxy Functions"
                initialValue={config.proxyFunctionInlining.isEnabled}
                onChange={(value) =>
                  setConfig(((config.proxyFunctionInlining.isEnabled = value), config))
                }
              />
              <ConfigItem
                name="Recover Strings"
                initialValue={config.stringRevealing.isEnabled}
                onChange={(value) =>
                  setConfig(((config.stringRevealing.isEnabled = value), config))
                }
              />
              <ConfigItem
                name="Recover Control Flow"
                initialValue={config.controlFlowRecovery.isEnabled}
                onChange={(value) =>
                  setConfig(
                    (((config.controlFlowRecovery.isEnabled = value),
                    (config.sequenceSplitting.isEnabled = value)),
                    config),
                  )
                }
              />
              <ConfigItem
                name="Remove Anti-Tamper"
                initialValue={config.antiTamperRemoval.isEnabled}
                onChange={(value) =>
                  setConfig(((config.antiTamperRemoval.isEnabled = value), config))
                }
              />
              <ConfigItem
                name="Remove Dead Code"
                initialValue={config.deadBranchRemoval.isEnabled}
                onChange={(value) =>
                  setConfig(
                    ((config.deadBranchRemoval.isEnabled =
                      config.constantPropagation.isEnabled =
                      config.reassignmentRemoval.isEnabled =
                      config.unusedVariableRemoval.isEnabled =
                        value),
                    config),
                  )
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
